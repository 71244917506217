
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, reactive, toRefs, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import {
  email,
  required,
  numeric,
  minLength,
  sameAs,
} from '@vuelidate/validators';
import {
  forgotPassword,
  confirmPassword as confirmPasswordService,
} from '@/services/aws-cognito.service';

import ErrorDisplay from '../components/shared/ErrorDisplay.vue';

export default defineComponent({
  components: {
    ErrorDisplay,
  },
  setup() {
    const success = ref(false);
    const isLoading = ref(false);
    const errorMessage = ref('A valid email is required');

    const isError = ref(false);
    const errorMessageConfirmPass = ref('');

    const router = useRouter();
    const route = useRoute();

    const loginForm = reactive({
      email: '',
    });

    const passwordRecoveryForm = reactive({
      code: '',
      newPassword: '',
      confirmPassword: '',
      username: route.query.email,
    });

    const emailRules = computed(() => ({
      email: { required, email },
    }));

    const passwordRecoveryRules = computed(() => ({
      code: { required, numeric, minLength: minLength(4) },
      newPassword: {
        required,
        minLength: minLength(8),
        goodPassword: (password: string) => {
          //a custom validator!
          return (
            /[a-z]/.test(password) &&
            /[A-Z]/.test(password) &&
            /[0-9]/.test(password)
          );
        },
      },
      confirmPassword: {
        required,
        minLength: minLength(8),
        sameAs: sameAs(passwordRecoveryForm.newPassword),
      },
    }));

    const v$ = useVuelidate(emailRules, loginForm);
    const v2$ = useVuelidate(passwordRecoveryRules, passwordRecoveryForm);

    const handleOnComplete = (value: string) => {
      passwordRecoveryForm.code = value;
    };

    const handleOnChange = (value: string) => {
      passwordRecoveryForm.code = value;
    };

    const handleResetPassword = async () => {
      isLoading.value = true;
      forgotPassword(loginForm.email ?? route.query.email, (err: any) => {
        isLoading.value = false;
        if (err) {
          console.log(err);
        } else {
          success.value = true;
          passwordRecoveryForm.username = loginForm.email;
          router.push({ query: { email: loginForm.email } });
        }
      });
    };
    const handleResetPasswordCode = async () => {
      isLoading.value = true;
      confirmPasswordService(
        passwordRecoveryForm.username,
        passwordRecoveryForm.code,
        passwordRecoveryForm.newPassword,
        // @ts-ignore
        (err: any) => {
          isLoading.value = false;

          if (err) {
            isError.value = true;
            errorMessageConfirmPass.value = err?.message ? err.message : err;
            console.log(err);
          } else {
            router.replace('/login');
          }
        }
      );
    };

    return {
      handleResetPassword,
      handleResetPasswordCode,
      handleOnComplete,
      handleOnChange,
      success,
      route,
      errorMessage,
      isError,
      isLoading,
      errorMessageConfirmPass,
      v$,
      v2$,
      ...toRefs(loginForm),
      ...toRefs(passwordRecoveryForm),
    };
  },
});
